import React from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Label, SelectSkillsPopup, SkillItem } from 'components';
import { SkillStateItem } from 'components/SelectSkillsPopup';
import { SalonInterface, SalonsCustomSkillInterface } from 'modules/salon/interface';
import AddButton from 'containers/Event/components/AddButton';

import { getListSkillsSelector } from 'modules/skills';
import { FullSkillListItemInterface, ContractorrsSkillInterface } from 'modules/skills/interface';
import { getSalonsSkills } from 'helpers/skills';
import {
    getMastersSkillsRequest,
    getMastersSkillsSelector,
    getMastersCustomSkillSelector,
    getMastersCustomSkillsRequest,
} from 'modules/masters';

import style from './style.scss';

const SelectKills = ({
    setFieldValue,
    values,
    errors,
    salonData,
    disabled = false,
    skillsName = 'skills',
    servicesName = 'services',
    useCombinedChange = false,
    combinedName = 'skills_services',
}: {
    setFieldValue: (field: string, value: any) => void;
    values: Record<string, any>;
    errors: Record<string, string | undefined>;
    salonData: SalonInterface;
    disabled?: boolean;
    skillsName?: string;
    servicesName?: string;
    useCombinedChange?: boolean;
    combinedName?: string;
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { skills: salonSkills = [], services: customSkills = [], currency_id } = salonData; // salon data (salonSkills - list of skills of salon   services - list of custom skills of salon)
    const fullListOfSkills = useSelector<any, FullSkillListItemInterface[]>(getListSkillsSelector); // list of all skills
    const selectedSkillsIds = values['skills'] as SkillStateItem[];
    const selectedCustomSkillsIds = values['services'] as SkillStateItem[];
    const masterId = values['master_id'];

    React.useEffect(() => {
        if (masterId) {
            dispatch(getMastersSkillsRequest({ id: masterId }));
            dispatch(getMastersCustomSkillsRequest({ id: masterId }));
        }
    }, [masterId]);

    const fullDataSelectedSkills = React.useMemo(() => {
        // use for show data of selected skills
        return salonSkills.filter(i => selectedSkillsIds.find(item => item == i.id));
    }, [selectedSkillsIds, salonSkills]);

    const fullDataSelectedCustomSkills = React.useMemo(() => {
        // use for show data of selected custom skills`
        return customSkills.filter(i => selectedCustomSkillsIds.find(item => item == i.id));
    }, [selectedCustomSkillsIds, customSkills]);

    const textError = errors['skills'];

    const masterSkills = useSelector<any, ContractorrsSkillInterface[]>(getMastersSkillsSelector);

    const masterCustomSkills = useSelector<any, SalonsCustomSkillInterface[]>( // list of customs kills selected master
        getMastersCustomSkillSelector
    );

    //  list of custm skills   filtred by master
    const { preparedCustomSkills, injectedCustomSkills } = React.useMemo(() => {
        const salonFiltredCustomSkills = masterId
            ? customSkills.filter(i => masterCustomSkills.find(item => item.id === i.id)) // macth custom skills of master and salon custom skills
            : customSkills;
        return {
            preparedCustomSkills: salonFiltredCustomSkills
                .filter(elem => !elem.parent_uid || elem.parent_uid === 'parent_uid')
                .map(i => ({
                    ...i,
                    id: i.id.toString(),
                    price: i.price.toString(),
                    is_unique_custom_service: true,
                })),
            injectedCustomSkills: salonFiltredCustomSkills
                .filter(elem => elem.parent_uid && elem.parent_uid !== 'parent_uid')
                .map(i => ({
                    ...i,
                    id: i.id.toString(),
                    price: i.price.toString(),
                    is_unique_custom_service: true,
                })),
        };
    }, [customSkills, masterCustomSkills, masterId]);

    //  prepares list of skills  with categories and subcategories  TODO: move to skillsSelectPopup
    const salonFiltredSkills = React.useMemo<ContractorrsSkillInterface[]>(() => {
        return getSalonsSkills(
            fullListOfSkills,
            masterId ? masterSkills : salonSkills,
            injectedCustomSkills
        );
    }, [salonSkills, fullListOfSkills, masterSkills, masterId, injectedCustomSkills]);

    const [showPopupSkill, switchPopupSkill] = React.useState<boolean>(false); // used for showing popup

    return (
        <>
            <div className={style.itemContainer}>
                <Label
                    fontSize={20}
                    title={Boolean(selectedSkillsIds.length) && t('generic_services')}
                >
                    <div className={selectedSkillsIds.length ? style.selectedItemsContainer : ''}>
                        {fullDataSelectedSkills.map((i: any) => (
                            <div key={i?.id} className={style.skillItemContainer}>
                                <SkillItem
                                    showDuration
                                    showPrice
                                    showEdit={false}
                                    showDelete={!disabled}
                                    currency_id={currency_id}
                                    data={{
                                        ...i,
                                        price: get(i, 'pivot.price', 0),
                                        duration: get(i, 'pivot.duration'),
                                    }}
                                    onDelete={() => {
                                        setFieldValue(
                                            skillsName,
                                            selectedSkillsIds.filter(item => item !== i.id)
                                        );
                                    }}
                                />
                            </div>
                        ))}
                        {fullDataSelectedCustomSkills.map((i: any) => (
                            <div key={i.id} className={style.skillItemContainer}>
                                <SkillItem
                                    showDuration
                                    showPrice
                                    showEdit={false}
                                    showDelete={!disabled}
                                    currency_id={currency_id}
                                    data={{
                                        ...i,
                                        price: get(i, 'price'),
                                        duration: get(i, 'duration'),
                                    }}
                                    onDelete={() => {
                                        setFieldValue(
                                            servicesName,
                                            selectedCustomSkillsIds.filter(item => item != i.id)
                                        );
                                    }}
                                />
                            </div>
                        ))}
                        <AddButton
                            textError={textError ? t('search_choose_a_service') : ''}
                            disabled={disabled}
                            onClick={() => switchPopupSkill(!showPopupSkill)}
                            variant={
                                fullDataSelectedCustomSkills?.length ||
                                fullDataSelectedSkills?.length
                                    ? 'button'
                                    : false
                            }
                            titleLine={t('search_add_the_services')}
                            titleButton={t('add_client_add')}
                        />
                    </div>
                </Label>
            </div>
            {showPopupSkill && (
                <SelectSkillsPopup
                    forceExpand
                    selectedSkills={selectedSkillsIds}
                    selectedCustom={selectedCustomSkillsIds}
                    customSkills={preparedCustomSkills}
                    skills={salonFiltredSkills}
                    showPopup={showPopupSkill}
                    title={t('add_master_services_provided')}
                    onCancel={() => {
                        if (useCombinedChange) {
                            setFieldValue(combinedName, { skills: [], customSkills: [] });
                        } else {
                            setFieldValue(skillsName, []);
                            setFieldValue(servicesName, []);
                        }
                    }}
                    onClose={() => {
                        switchPopupSkill(!showPopupSkill);
                    }}
                    onSubmit={(skills, customSkills) => {
                        if (useCombinedChange) {
                            setFieldValue(combinedName, { skills, customSkills });
                        } else {
                            setFieldValue(skillsName, skills);
                            setFieldValue(servicesName, customSkills);
                        }
                        switchPopupSkill(!showPopupSkill);
                    }}
                    showPrice
                    showDuration
                />
            )}
        </>
    );
};

export default SelectKills;
