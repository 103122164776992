export const ACTIVE_COUNTRIES_CODES = ['RU', 'BY', 'EN', 'AE', 'PL'];
// export const COUNTRY_DATA = { Россия: 'RU', Беларусь: 'BY' };

export const getCodesByCountryName = country => {
    switch (country) {
        case 'United Kingdom':
            return ['UK'];
        case 'Россия':
            return ['RU'];
        case 'Беларусь':
            return ['BY'];
        case 'United Arab Emirates':
            return ['AE'];
        case 'Poland':
            return ['PL'];
        default:
            return ACTIVE_COUNTRIES_CODES;
    }
};
export const PRICE_RANGES = Object.freeze({
    BYN: [0, 500],
    RUB: [0, 20000],
    EUR: [0, 1000],
    GBP: [0, 1000],
    AED: [0, 1000],
    PLN: [0, 1000],
});
export const DEFAULT_CITIES_BELARUS = [
    {
        value: 'Минск',
        label: 'Минск',
    },
    {
        value: 'Брест',
        label: 'Брест',
    },
    {
        value: 'Гомель',
        label: 'Гомель',
    },
    {
        value: 'Гродно',
        label: 'Гродно',
    },
    {
        value: 'Витебск',
        label: 'Витебск',
    },
    {
        value: 'Могилев',
        label: 'Могилев',
    },
];
export const DEFAULT_CITIES_RUSSIA = [
    {
        value: 'Москва',
        label: 'Москва',
    },
    {
        value: 'Санкт-Петербург',
        label: 'Санкт-Петербург',
    },
    {
        value: 'Новосибирск',
        label: 'Новосибирск',
    },
    {
        value: 'Екатеринбург',
        label: 'Екатеринбург',
    },
    {
        value: 'Нижний Новгород',
        label: 'Нижний Новгород',
    },
    {
        value: 'Казань',
        label: 'Казань',
    },
];
export const DEFAULT_CITIES_UK = [
    {
        value: 'London',
        label: 'London',
    },
    {
        value: 'Liverpool',
        label: 'Liverpool',
    },
    {
        value: 'Manchester',
        label: 'Manchester',
    },
    {
        value: 'Newcastle',
        label: 'Newcastle',
    },
    {
        value: 'Oxford',
        label: 'Oxford',
    },
    {
        value: 'Brighton',
        label: 'Brighton',
    },
];
export const DEFAULT_CITIES_AE_RUS = [
    {
        value: 'Дубай',
        label: 'Дубай',
    },
    {
        value: 'Абу-Даби',
        label: 'Абу-Даби',
    },
    {
        value: 'Шарджа',
        label: 'Шарджа',
    },
];
export const DEFAULT_CITIES_AE = [
    {
        value: 'Dubai',
        label: 'Dubai',
    },
    {
        value: 'Abu Dhabi',
        label: 'Abu Dhabi',
    },
    {
        value: 'Sharjah',
        label: 'Sharjah',
    },
];
export const DEFAULT_CITIES_PL = [
    {
        value: 'Warsaw',
        label: 'Warsaw',
    },
];
export const DEFAULT_CITIES_PL_RUS = [
    {
        value: 'Варшава',
        label: 'Варшава',
    },
];
export const getCitiesByCountry = country => {
    switch (country) {
        case 'Беларусь':
            return DEFAULT_CITIES_BELARUS;
        case 'Россия':
            return DEFAULT_CITIES_RUSSIA;
        case 'United Kingdom':
            return DEFAULT_CITIES_UK;
        case 'United Arab Emirates':
            return DEFAULT_CITIES_AE;
        case 'Объединённые Арабские Эмираты':
            return DEFAULT_CITIES_AE_RUS;
        case 'Польша':
            return DEFAULT_CITIES_PL_RUS;
        case 'Poland':
            return DEFAULT_CITIES_PL;
        default:
            return DEFAULT_CITIES_BELARUS;
    }
};
