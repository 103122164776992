import React from 'react';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { UserInfo, AddClientPopup } from 'components';
import { useSelector, useDispatch } from 'react-redux';
import { Label } from 'components';
import { SalonInterface } from 'modules/salon/interface';
import { getCurrencySelector } from 'modules/localization';
import AddButton from '../AddButton';
import AddPopup from '../AddPopup';
import { getClientsRequest, createClientRequest, getClientsSelector } from 'modules/clients';
import { ClientInterface } from 'modules/clients/interfaces';
import style from './style.scss';

const SelectClient = ({
    setFieldValue,
    values,
    errors,
    salonData,
    disabled = false,
    name = 'client_id',
}: {
    setFieldValue: (field: string, value: any) => void;
    values: Record<string, any>;
    errors: Record<string, string | undefined>;
    salonData: SalonInterface;
    disabled?: boolean;
    name?: string;
}) => {
    const { id } = salonData;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const clientId = values[name];
    React.useEffect(() => {
        if (id) {
            dispatch(getClientsRequest({ id }));
        }
    }, [id]);

    const [showAddPopup, switchAddPopup] = React.useState(false);
    const [showPopup, switchPopup] = React.useState(false);
    const clients = useSelector<any, ClientInterface[]>(getClientsSelector);
    const textError: string = errors[name] || '';
    const sellectedClient = clients.find(i => i.id === clientId);

    const countryCode = useSelector<any, string>(getCurrencySelector);

    const createClient = ({ phone, name }: { phone: string; name: string }) => {
        dispatch(
            createClientRequest(
                { id, phone, name },
                {
                    onSuccess: ({ data }: { data: ClientInterface }) => {
                        setFieldValue(name, data.id);
                        dispatch(getClientsRequest({ id }));
                    },
                }
            )
        );
    };

    const onChangeClient = (i: { id: string }) => {
        setFieldValue(name, i.id);
    };

    return (
        <>
            <div className={style.itemContainer}>
                <Label fontSize={20} title={sellectedClient ? t('generic_client') : ''}>
                    <div className={sellectedClient ? style.selectedItemsContainer : ''}>
                        {sellectedClient && <UserInfo data={sellectedClient} />}
                        <AddButton
                            onClick={() => {
                                if (clients && clients.length > 0) {
                                    switchAddPopup(!showAddPopup);
                                } else {
                                    switchPopup(!showPopup);
                                }
                            }}
                            variant={sellectedClient ? 'button' : false}
                            titleLine={t('generic_Add_a_client')}
                            titleButton={t('generic_edit')}
                            disabled={disabled}
                            textError={textError}
                        />
                        {!sellectedClient && (
                            <div className={style.addClientText}>
                                {t('event_no_client_in_the_database')}
                                <span
                                    className={style.link}
                                    onClick={() => {
                                        switchPopup(!showPopup);
                                    }}
                                >
                                    {t('event_add_a_new_one')}
                                </span>
                            </div>
                        )}
                    </div>
                </Label>
            </div>
            {showAddPopup && (
                <AddPopup
                    title={t('choose_client')}
                    items={clients}
                    onSubmit={i => {
                        onChangeClient(i);
                        switchAddPopup(!showAddPopup);
                    }}
                    onClose={() => {
                        switchAddPopup(!showAddPopup);
                    }}
                >
                    <div className={style.addClientText}>
                        {t('event_no_client_in_the_database')}
                        <div>
                            <Button
                                className={style.button}
                                color={'primary'}
                                variant="contained"
                                onClick={() => {
                                    switchAddPopup(!showAddPopup);
                                    switchPopup(!showPopup);
                                }}
                            >
                                {t('event_add_a_new_one')}
                            </Button>
                        </div>
                    </div>
                </AddPopup>
            )}
            {showPopup && (
                <AddClientPopup
                    showPopup={showPopup}
                    onClose={() => {
                        switchPopup(!showPopup);
                    }}
                    onSubmit={data => {
                        createClient(data);
                        switchPopup(!showPopup);
                    }}
                    countryCode={countryCode}
                    onCancel={() => {}}
                />
            )}
        </>
    );
};

export default SelectClient;
