import React from 'react';
import PropTypes from 'prop-types';
import { WorkingTimeSelect, Skeleton } from 'components';
import { useSelector, useDispatch } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { recoveryDataForWorkTime } from 'helpers/calendar.js';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useTranslation } from 'react-i18next';
import { getSalonSelector, updateSalonInfoRequest, getSalonInfoSelector } from 'modules/salon';
import {
    getSalonWorkTimeRequest,
    updateSalonWorkTimeRequest,
    getSalonWorkingTimeSelector,
} from 'modules/working_time';

import style from './style.scss';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        // marginTop: theme.spacing(2),
    },
}));
const InitWorkingTime = ({ history, ...props }) => {
    const classes = useStyles();
    const workingTime = useSelector(getSalonWorkingTimeSelector);
    const dispatch = useDispatch();
    const { id } = useSelector(getSalonSelector);
    const { working_time_interval, min_time_until_event } = useSelector(getSalonInfoSelector);
    const [time, setTime] = React.useState({});
    const [interval, setIntervalTime] = React.useState(60);
    const [minFreeTime, setSetMinFreeTime] = React.useState(15);
    const [showCircles, setSetCircle] = React.useState(false);
    const [textError, setTextError] = React.useState('');
    const { t } = useTranslation();

    React.useEffect(() => {
        if (id) {
            dispatch(getSalonWorkTimeRequest({ id }));
        }
    }, [id]);
    React.useEffect(() => {
        setTime(workingTime);
    }, [workingTime]);
    React.useEffect(() => {
        setIntervalTime(working_time_interval);
        setSetMinFreeTime(min_time_until_event);
    }, [working_time_interval]);
    return (
        <Skeleton
            breadcamps={[{ link: '/profile', title: t('menu_profile') }]}
            textError={textError}
            progress={90}
            showBreadcump
            title={t('synch_work_schedule')}
            nextButtonText={t('generic_save')}
            subTitle={``}
            onBack={() => {
                history.push('/init-portfolio');
            }}
            onNext={() => {
                switch (true) {
                    case recoveryDataForWorkTime(time).length == 0: {
                        setTextError(t('synch_set_the_working'));
                        return;
                    }
                    default: {
                        dispatch(updateSalonWorkTimeRequest({ id, data: time }));
                        dispatch(
                            updateSalonInfoRequest({
                                id,
                                working_time_interval: interval,
                                min_time_until_event: minFreeTime,
                            })
                        );
                        history.push('/profile');
                        return;
                    }
                }
            }}
        >
            <Grid container>
                <Grid item xs={12} md={9}>
                    <div className={style.controls}>
                        <FormControl className={classes.formControl}>
                            <InputLabel
                                className={style.labelContainer}
                                shrink
                                id="demo-simple-select-label"
                            >
                                {t('quick_disable_interval_date_label')}
                                <Tooltip title={t('shedule_set_the_interval')}>
                                    <ErrorOutlineIcon color="primary" className={style.infoIcon} />
                                </Tooltip>
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                value={interval}
                                onChange={ev => {
                                    setIntervalTime(ev.target.value);
                                }}
                                name="interval"
                            >
                                <MenuItem value={10}>10 {t('generic_min')}</MenuItem>
                                <MenuItem value={15}>15 {t('generic_min')}</MenuItem>
                                <MenuItem value={20}>20 {t('generic_min')}</MenuItem>
                                <MenuItem value={30}>30 {t('generic_min')}</MenuItem>
                                <MenuItem value={60}>60 {t('generic_min')}</MenuItem>
                                <MenuItem value={90}>90 {t('generic_min')}</MenuItem>
                                <MenuItem value={120}>120 {t('generic_min')}</MenuItem>
                            </Select>
                        </FormControl>

                        <Tooltip title={t('shedule_show_working_intervals')}>
                            <FormControlLabel
                                className={style.sheduleSwitch}
                                control={
                                    <Checkbox
                                        checked={showCircles}
                                        onChange={() => setSetCircle(!showCircles)}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label={t('shedule_show_intervals')}
                            />
                        </Tooltip>
                    </div>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={9}>
                    <WorkingTimeSelect
                        startWeekDay={1}
                        isMobile
                        showIntervals={showCircles}
                        interval={interval}
                        startTime={300}
                        endTime={1440}
                        onChange={data => {
                            setTime(data);
                        }}
                        workingTimeIntervals={time}
                    />
                </Grid>
            </Grid>
        </Skeleton>
    );
};

InitWorkingTime.propTypes = {
    // : PropTypes.
};

export default InitWorkingTime;
