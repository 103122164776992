import moment from 'moment';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { getSalonSelector } from 'modules/salon';
import { SalonInterface } from 'modules/salon/interface';
import { getEventRequest, getEventSelector, updateEventRequest } from 'modules/events';
import { showPopupAction } from 'modules/popups';
import { EventInterface } from 'modules/events/interface';
import { createFeedbackRequest } from 'modules/feedback';
import * as yup from 'yup';
import { debounce } from 'lodash';

const validationSchema = (t: (x: any) => any) =>
    yup.object({
        date: yup.string().required(t('search_choose_a_date')),
        user_id: yup.string().required(t('event_select_a_client')),
        master_id: yup.number().required(t('search_choose_a_beautician')),
        skills: yup
            .array()
            .of(yup.string())
            .test('skills-or-services', t('event_select_at_least_one_service'), function(
                skills: any
            ) {
                const { services } = this.parent;
                return skills.length > 0 || services.length > 0;
            }),
        services: yup.array().of(yup.string()),
    });

interface ValueInterface {
    user_id: string | number;
    master_id: string | number;
    date: string | undefined;
    amount: number;
    duration: number;
    skills: any[];
    services: any[];
    internal_comment: string;
    comment: string;
    // amount: number;
    status_id: number;
    payment_type_id: number;
}

export const useEditeEvent = ({
    history,
    eventId,
}: {
    history: any;
    eventId?: number | string;
}): {
    handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    values: any;
    errors: any;
    touched: any;
    setFieldValue: (field: string, value: any) => void;
    salonData: SalonInterface;
    eventRanges: {
        started: boolean;
        ended: boolean;
    };
    event: EventInterface;
    addFeedback: (data: { rating: number | string; feedback: string }) => void;
    combinedName: string;
} => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const salonData = useSelector<(state: any) => SalonInterface, SalonInterface>(getSalonSelector);
    const { id: salonId } = salonData;

    React.useEffect(() => {
        if (salonId && eventId) {
            dispatch(getEventRequest({ id: salonId, event_id: eventId }));
        }
    }, [salonData?.id, eventId]);

    const event = useSelector<any, EventInterface>(getEventSelector);

    const initialValues = {
        user_id: event?.user_id || '',
        master_id: event?.master_id || '',
        date: event?.date || '',
        amount: event?.amount || 0,
        duration: event?.duration || 1,
        skills: event?.skills?.map(i => i.id) || [],
        services: event?.services?.map(i => i.id) || [],
        internal_comment: event?.internal_comment || '',
        comment: event?.comment || '',
        status_id: event?.status_id || 1,
        payment_type_id: event?.payment_type_id || 0,
    } as ValueInterface;

    const { handleSubmit, values, errors, touched, setFieldValue } = useFormik({
        initialValues: {
            ...initialValues,
        },
        validationSchema: validationSchema(t),
        enableReinitialize: true,
        isInitialValid: true,

        onSubmit: values => {},
    });

    const { duration } = values;
    const { date } = values;

    const eventRanges = {
        started: new Date() > moment(date).toDate(),
        ended:
            new Date() >
            moment(date)
                .add(duration, 'minutes')
                .toDate(),
    };

    const addFeedback = ({ rating, feedback }: { rating: number | string; feedback: string }) => {
        dispatch(
            createFeedbackRequest(
                { id: eventId, rating, feedback },
                {
                    onSuccess: () => {
                        dispatch(getEventRequest({ id: salonId, event_id: eventId }));
                    },
                }
            )
        );
    };

    const debouncedUpdate = React.useCallback(
        debounce((update: any) => {
            update();
        }, 1000),
        [] // upd
    );

    const combinedName = 'skills_services';
    type CombinedName = typeof combinedName;
    type ExtendedKey = keyof ValueInterface & CombinedName;
    type PartialValueInterface = Partial<ValueInterface> & { utc_date?: string };

    const setFieldValueProxy = (field: string, value: any) => {
        if (values[field as ExtendedKey] == value) return;

        const update = ({
            redirect = false,
            onSuccess = () => {},
            showLoader = false,
            data = {},
        }: {
            redirect?: boolean;
            onSuccess?: () => void;
            showLoader?: boolean;
            data?: PartialValueInterface;
        }) => {
            if (!salonId || !eventId) return;
            dispatch(
                updateEventRequest(
                    {
                        id: salonId,
                        status_id: values.status_id,
                        event_id: eventId,
                        ...data,
                        showLoader,
                    },
                    {
                        onSuccess: () => {
                            onSuccess();
                            dispatch(
                                getEventRequest({
                                    id: salonId,
                                    event_id: eventId,
                                    showLoader: false,
                                })
                            );
                            if (redirect) {
                                history.push('/calendar');
                            }
                        },
                        onFailure: () => {
                            dispatch(getEventRequest({ id: salonId, event_id: eventId }));
                        },
                    }
                )
            );
        };

        const updateWithPopup = ({
            redirect = false,
            onSuccess = () => {},
            onConfirm = () => {},
            showLoader = false,
            data = {},
        }: {
            redirect?: boolean;
            onSuccess?: () => void;
            onConfirm?: () => void;
            showLoader?: boolean;
            data?: PartialValueInterface;
        }) => {
            dispatch(
                showPopupAction({
                    message: t('event_save_the_changes'),
                    cancelButtonText: t('generic_cancel'),
                    textError: '',
                    onClick: () => {
                        onConfirm();
                        update({ redirect, onSuccess, showLoader, data });
                        return true;
                    },
                    onCancel: () => {
                        return true;
                    },
                })
            );
        };

        switch (field) {
            case combinedName: {
                updateWithPopup({
                    data: { skills: value.skills || [], services: value.customSkills || [] },
                    onSuccess: () => {
                        // setFieldValue('skills', value.skills);
                        // setFieldValue('services', value.customSkills);
                    },
                });
                break;
            }
            case 'skills': {
                updateWithPopup({
                    data: { [field]: value },
                    onSuccess: () => {
                        // setFieldValue(field, value);
                    },
                });
                break;
            }
            case 'services': {
                updateWithPopup({
                    data: { [field]: value },
                    onSuccess: () => {
                        // setFieldValue(field, value);
                    },
                });
                break;
            }
            case 'date': {
                updateWithPopup({
                    data: { [field]: value },
                    onSuccess: () => {
                        setFieldValue(field, value);
                    },
                });
                break;
            }

            case 'amount': {
                setFieldValue(field, value);
                debouncedUpdate(() => {
                    update({
                        data: { [field]: value },
                        onSuccess: () => {
                            setFieldValue(field, value);
                        },
                    });
                });
                break;
            }
            case 'master_id': {
                updateWithPopup({
                    data: { [field]: value },
                    onSuccess: () => {
                        setFieldValue(field, value);
                    },
                });
                break;
            }
            case 'duration': {
                setFieldValue(field, value);
                debouncedUpdate(() => {
                    update({
                        data: { [field]: value },
                    });
                });

                break;
            }

            case 'status_id': {
                updateWithPopup({
                    data: { [field]: value, payment_type_id: values.payment_type_id },
                    onSuccess: () => {
                        setFieldValue(field, value);
                    },
                });
                break;
            }
            case 'comment': {
                setFieldValue(field, value);
                debouncedUpdate(() => {
                    update({
                        data: { [field]: value },
                    });
                });
                break;
            }
            case 'internal_comment': {
                setFieldValue(field, value);
                debouncedUpdate(() => {
                    update({
                        data: { [field]: value },
                    });
                });
                break;
            }
            case 'payment_type_id': {
                setFieldValue(field, value);
                break;
            }

            default: {
                break;
            }
        }
    };

    return {
        handleSubmit,
        values,
        errors,
        touched,
        setFieldValue: setFieldValueProxy,
        salonData,
        eventRanges,
        event,
        addFeedback,
        combinedName,
    };
};
