import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import PopupBackground from 'components/Popup';
import PhoneInput from '../PhoneInput';
import { useTranslation } from 'react-i18next';
import style from './style.scss';
const color = '#FF6335"';
// <div className={style.closeButtonContainer}>
//     <IconButton
//         size="small"
//         onClick={() => {
//             onClose();
//         }}
//     >
//         <ClearIcon />
//     </IconButton>
// </div>

const Popup = ({ onSubmit, onCancel, onClose, showPopup, countryCode }) => {
    const [phone, setPhone] = React.useState('');
    const [name, setName] = React.useState('');
    const [textError, setErrorText] = React.useState('');
    const [textInfo, setInfoText] = React.useState('');
    const { t } = useTranslation();
    return (
        <PopupBackground
            onSubmit={() => {
                switch (true) {
                    case phone.length < 6:
                        setErrorText(t('enter_client_phone_number'));
                        return;
                    case name.length < 1:
                        setErrorText(t('add_client_client_name'));
                        return;
                    default: {
                        onSubmit({ phone: phone.replace(/[^0-9.]/g, ''), name });
                        return;
                    }
                }
            }}
            onCancel={() => {
                onCancel();
            }}
            textError={textError}
            textInfo={textInfo}
            showCancel={false}
            showClear
            onClear={() => {
                onClose();
                return true;
            }}
            showPopup={showPopup}
            visible
            title={t('add_client_add_a_new_client')}
            submitButtonText={t('add_client_add')}
            childrenContainerClassName={style.dataContainer}
            popupBackgroundsProps={{ onClick: onClose, childrenClassName: style.container }}
        >
            <div className={style.topBlock}>
                <div>
                    <PhoneInput
                        countryCode={countryCode}
                        label={t('add_client_phone_number')}
                        value={phone}
                        onChange={setPhone}
                    />
                </div>
                <div className={style.inputName}>
                    <TextField
                        fullWidth
                        label={t('generic_name')}
                        required
                        value={name}
                        onChange={ev => {
                            setName(ev.target.value);
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                </div>
            </div>
        </PopupBackground>
    );
};

Popup.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    showPopup: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    countryCode: PropTypes.string.isRequired,
};

export default Popup;
