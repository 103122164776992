import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '../../components/Skeleton';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { getListSkillsSelector } from 'modules/skills';
import {
    addCustomSkillSubSkillsRequest,
    updateSalonCustomSkillRequest,
    createSalonCustomSkillRequest,
    getCustomSkillsRequest,
    getSalonCustomSkillsSelector,
} from 'modules/skills/customSkills';
import { getSalonSelector } from 'modules/salon';
import { NumberFormat, IncreasePicker } from 'components';
import { Select } from 'feelqueen_components';
import { SkillItem, SelectSkillsPopup } from 'components';
import { getAllSkills } from 'helpers/skills';
import { useTranslation } from 'react-i18next';
import style from './style.scss';

const useStyles = makeStyles(theme => ({
    root: {
        background: '#FFFAF6',
        border: '2px solid #FA835F',
        color: '#FA835F',
        margin: 'auto 10px;',
    },
    formControll: {
        width: '100%',
        marginTop: '26px',
    },
    setKeySkills: {
        marginTop: '23px',
    },
}));

const saveNumber = ({ setNumber, ev }) => {
    const str = ev.target.value || '';
    const number = str.replace(/[^0-9.]/g, '');
    if (number >= 0) {
        setNumber(number);
    }
};

const CustomSkills = ({
    history,
    match: {
        params: { id: skillId },
    },
    ...props
}) => {
    const [textError, setTextError] = React.useState('');
    const [submitPushed, setSubmitPush] = React.useState(false);
    const [showPopupSkill, switchSkillsPopup] = React.useState(false);
    const [selectedKeysSkills, setKeysSkillsItems] = React.useState([]);
    const classes = useStyles();
    const dispatch = useDispatch();
    const skills = useSelector(getListSkillsSelector);
    const { id } = useSelector(getSalonSelector);
    const [name, setName] = React.useState();
    const [description, setDescription] = React.useState();
    const [duration, setDuration] = React.useState();
    const [price, setPrice] = React.useState();
    const [parentUid, setParentUid] = React.useState();
    const customSkills = useSelector(getSalonCustomSkillsSelector);
    const { t } = useTranslation();
    React.useEffect(() => {
        if (id) {
            dispatch(getCustomSkillsRequest({ id }));
        }
    }, [id]);
    React.useEffect(() => {
        if (skillId) {
            const skill = customSkills.find(i => i.id == skillId);

            if (skill) {
                const {
                    duration,
                    id,
                    parent_skill_id,
                    description,
                    parent_uid,
                    price,
                    title,
                    related_skills,
                } = skill;

                setDuration(duration);
                setName(title);
                setParentUid(parent_uid);
                setDescription(description);
                setPrice(price);
                setKeySkills(related_skills);
            }
        }
    }, [customSkills, skillId]);

    const addCustomSkills = () => {
        setSubmitPush(true);
        switch (true) {
            case !name || !price || !duration:
                setTextError(t('profile_required_fields'));
                return;

            default:
                if (!skillId) {
                    dispatch(
                        createSalonCustomSkillRequest({
                            id,
                            title: name,
                            duration,
                            description,
                            price,
                            parent_uid: parentUid,
                            related_skills: selectedKeysSkills.map(i => i.id),
                        })
                    );
                } else {
                    dispatch(
                        updateSalonCustomSkillRequest({
                            id,
                            service_id: skillId,
                            title: name,
                            duration,
                            description,
                            price,
                            parent_uid: parentUid,
                            related_skills: selectedKeysSkills.map(i => i.id),
                        })
                    );
                }
                history.goBack();
                return;
        }
    };

    const preparedSkills = React.useMemo(() => {
        return skills.filter(i => i.uid === parentUid);
    }, [parentUid, skills]);

    const skillsOptionbs = skills.map(i => ({ value: i.uid, label: i.title }));
    const setKeySkills = (data = []) => {
        const selectedSkills = getAllSkills(skills).filter(i => data.find(item => i.id == item.id));
        setKeysSkillsItems(selectedSkills);
    };
    return (
        <Skeleton
            textError={textError}
            title={
                skillId
                    ? t('custom_skill_editing_a_unique_service')
                    : t('custom_skill_editing_a_unique_service_add')
            }
            showProgressBar={false}
            onBack={() => {
                history.goBack();
            }}
            subTitle={t('custom_skill_you_can_create_a_service')}
            onNext={addCustomSkills}
            nextButtonText={skillId ? t('custom_skill_Save_save') : t('custom_skill_Save_Add')}
            backButtonText={t('generic_back')}
        >
            <Grid container>
                <Grid item md={6} xs={12}>
                    <div className={style.itemForm}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            margin="normal"
                            required
                            label={t('custom_skill_name_of_service')}
                            fullWidth
                            value={name}
                            onChange={ev => {
                                setName(ev.target.value);
                            }}
                            helperText={
                                submitPushed && !name ? (
                                    <span className={style.errorText}>
                                        {t('custom_skill_specify_a_name_of_service')}
                                    </span>
                                ) : (
                                    ''
                                )
                            }
                            placeholder={t('custom_skill_enter_a_name')}
                        />
                    </div>
                    <div className={style.itemForm}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            margin="normal"
                            label={t('generic_description')}
                            fullWidth
                            value={description}
                            onChange={ev => {
                                if (ev.target.value.length <= 256) {
                                    setDescription(ev.target.value);
                                }
                            }}
                            multiline
                            rowsMax={8}
                            placeholder={t('custom_skill_description_of_the_service')}
                        />
                    </div>
                    <div className={style.itemForm}>
                        <TextField
                            onChange={ev => {
                                saveNumber({ setNumber: setPrice, ev });
                            }}
                            InputLabelProps={{ shrink: true }}
                            margin="normal"
                            required
                            fullWidth
                            InputProps={{
                                inputProps: {
                                    displayType: 'input',
                                },
                                inputComponent: NumberFormat,
                            }}
                            label={t('generic_price')}
                            value={price}
                            placeholder={t('generic_total_price')}
                            helperText={
                                submitPushed && !price ? (
                                    <span className={style.errorText}>
                                        {t('custom_skill_price_for_service')}
                                    </span>
                                ) : (
                                    ''
                                )
                            }
                        />
                    </div>
                    <div className={style.itemForm}>
                        <FormControl classes={{ root: classes.formControll }}>
                            <InputLabel shrink htmlFor="my-input">
                                {t('custom_skill_service_category')}
                            </InputLabel>
                            <Select
                                placeholder=""
                                variant="text"
                                value={parentUid ? parentUid : null}
                                options={skillsOptionbs}
                                onChange={ev => {
                                    setParentUid(ev.target.value);
                                    setKeySkills([]);
                                }}
                            />
                        </FormControl>
                        <FormControl classes={{ root: classes.formControll }}>
                            <InputLabel shrink htmlFor="my-input">
                                {t('custom_skill_key_services')}
                            </InputLabel>
                            <div className={style.keySkillsContainer}>
                                {selectedKeysSkills.map(i => (
                                    <SkillItem
                                        key={i.id}
                                        data={i}
                                        showDuration={false}
                                        showPrice={false}
                                        showEdit={false}
                                        showInfo={false}
                                        onDelete={() => {
                                            setKeySkills(
                                                selectedKeysSkills.filter(j => j.id !== i.id)
                                            );
                                        }}
                                    />
                                ))}
                            </div>
                            <div>
                                <Button
                                    className={classes.setKeySkills}
                                    size="small"
                                    onClick={() => {
                                        switchSkillsPopup(!showPopupSkill);
                                    }}
                                    disabled={!parentUid}
                                    variant="contained"
                                    color="primary"
                                >
                                    {`+ ${t('search_add_the_services')}`}
                                </Button>
                            </div>
                        </FormControl>
                    </div>
                    <div className={style.itemFormLast}>
                        <IncreasePicker
                            suffix={t('generic_min')}
                            min={0}
                            max={500}
                            label={t('generic_duration')}
                            step={5}
                            placeholder={t('foe_example_min')}
                            value={duration}
                            onChange={setDuration}
                            helperText={
                                submitPushed && !duration ? (
                                    <span className={style.errorText}>
                                        {t('custom_skill_service_duration')}
                                    </span>
                                ) : (
                                    ''
                                )
                            }
                        />
                    </div>
                    {showPopupSkill && (
                        <SelectSkillsPopup
                            selectedSkills={selectedKeysSkills}
                            skills={preparedSkills}
                            showPopup={showPopupSkill}
                            title={t('custom_skill_select_key_services')}
                            skillsSelectProps={{
                                defaultExpand: true,
                                showSelectedItemsCount: true,
                            }}
                            onCancel={() => {
                                setKeySkills([]);
                                switchSkillsPopup(!showPopupSkill);
                            }}
                            onClose={() => {
                                switchSkillsPopup(!showPopupSkill);
                            }}
                            onSubmit={data => {
                                setKeySkills(data);
                                switchSkillsPopup(!showPopupSkill);
                            }}
                            selected={selectedKeysSkills}
                            setSelected={setKeysSkillsItems}
                        />
                    )}
                </Grid>
            </Grid>
        </Skeleton>
    );
};

CustomSkills.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object,
};

export default CustomSkills;
