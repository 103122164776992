import React from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { getSalonSelector } from 'modules/salon';
import { getCurrencySelector } from 'modules/localization';

const NumberFormatCustom = ({
    inputRef,
    suffix,
    onChange,
    name,
    displayType = 'text',
    value,
    thousandSeparator = ' ',
    ...other
}: {
    inputRef?: (x: any) => void;
    suffix?: string;
    onChange?: (event: { target: { name: string | undefined; value: string } }) => void;
    name?: string;
    displayType?: 'text' | 'input' | undefined;
    value?: string | number;
    thousandSeparator?: string;
}) => {
    const { id, currency_id } = useSelector<any, any>(getSalonSelector);
    const currency = useSelector<any, any[]>(getCurrencySelector);
    const currencyCurrent = React.useMemo(() => {
        return (currency.find(i => i.id == currency_id) || {}).badge?.toUpperCase();
    }, [currency_id, currency]);
    return (
        <NumberFormat
            {...other}
            displayType={displayType}
            thousandSeparator={thousandSeparator}
            suffix={suffix ? ` ${suffix}` : ` ${currencyCurrent || ''}`}
            getInputRef={inputRef}
            onValueChange={values => {
                if (onChange)
                    onChange({
                        target: {
                            name: name,
                            value: values.value,
                        },
                    });
            }}
            value={value}
            isNumericString
        />
    );
};
NumberFormatCustom.defaultProps = {
    onChange: () => {},
};

export default NumberFormatCustom;
