import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { useSelector, useDispatch } from 'react-redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { Skeleton, SkillsSelect, SkillItem } from 'components';
import {
    getListSkillsSelector,
    getSkillsRequest,
    setSkillsRequest,
    getSalonSkillsSelector,
} from 'modules/skills';
import { getSalonSelector } from 'modules/salon';
import { deleteCustomSkillSubSkillsRequest } from 'modules/skills/customSkills';
import { RouteContext } from 'contexts/route';
import style from './style.scss';

const color = '#FF6335';

const ServiceEdit = ({ history, ...props }) => {
    const dispatch = useDispatch();
    const { route } = useContext(RouteContext);
    const [search, setSearch] = React.useState('');
    const [selectedSkills, setSelectedSkills] = React.useState(
        JSON.parse(localStorage.getItem('selectedSkills'))
            ? JSON.parse(localStorage.getItem('selectedSkills'))
            : []
    );
    const { id, services = [], currency_id } = useSelector(getSalonSelector);
    const initSkills = useSelector(getSalonSkillsSelector);
    const skills = useSelector(getListSkillsSelector);
    const [textError, setTextError] = React.useState('');
    const [textInfo, setTextInfo] = React.useState('');
    const [showOnlySkills, setShowOnlySkills] = React.useState(false);
    const { t } = useTranslation();

    React.useEffect(() => {
        if (route.from !== '/init-custom-skills') {
            setSelectedSkills([
                ...initSkills.map(i => {
                    return {
                        id: i.id,
                        price: get(i, 'pivot.price'),
                        duration: get(i, 'pivot.duration'),
                    };
                }),
            ]);
        }
        if (route.from === '/init-custom-skills') {
            setSelectedSkills(JSON.parse(localStorage.getItem('selectedSkills')));
        }
    }, [initSkills]);

    React.useEffect(() => {
        return () => {
            localStorage.setItem('selectedSkills', JSON.stringify(selectedSkills));
        };
    }, [selectedSkills]);

    React.useEffect(() => {
        if (id) {
            dispatch(getSkillsRequest({ id }));
        }
    }, [id]);
    React.useEffect(() => {
        if (selectedSkills.length) {
            setTextInfo(
                `${t('services_services_selected')} ${selectedSkills.length + services.length}`
            );
        }
    }, [selectedSkills]);

    const onSubmit = () => {
        switch (true) {
            case !selectedSkills.length && !services.length: {
                setTextError(t('services_services_provided'));
                setShowOnlySkills(selectedSkills.map(i => i.id));
                return;
            }
            case !!selectedSkills.find(i => {
                setShowOnlySkills(selectedSkills.map(i => i.id));
                return i.price === undefined;
            }): {
                setTextError(t('services_service_price'));
                return;
            }
            case !!selectedSkills.find(i => {
                return i.duration == undefined;
            }): {
                setTextError(t('services_service_duration'));
                return;
            }
            default: {
                const preparedSkills = {};
                selectedSkills.forEach(i => {
                    preparedSkills[i.id] = { price: i.price, duration: i.duration };
                });
                dispatch(
                    setSkillsRequest(
                        { id, data: preparedSkills },
                        {
                            onSuccess: () => {
                                history.goBack();
                            },
                        }
                    )
                );

                return;
            }
        }
    };
    return (
        <Skeleton
            nextButtonText={t('generic_save')}
            textError={textError}
            textInfo={textInfo}
            showBreadcump
            breadcamps={[{ title: t('services_services_link'), link: '/services' }]}
            title={t('add_edit_services')}
            subTitle=""
            onNext={onSubmit}
        >
            <Grid container>
                <Grid item xs={12}>
                    <div className={style.searchContainer}>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon htmlColor={color} />
                                    </InputAdornment>
                                ),
                            }}
                            required
                            value={search}
                            onChange={ev => setSearch(ev.target.value)}
                            fullWidth
                            size="small"
                            variant="outlined"
                            placeholder={t('services_name_of_the_service')}
                        />
                    </div>
                    <div className={style.accordionContainer}>
                        <SkillsSelect
                            selected={selectedSkills}
                            showSelectedItemsCount
                            setSelected={setSelectedSkills}
                            skills={skills}
                            searchText={search}
                            addCustomSkills={() => {
                                history.push('/init-custom-skills');
                            }}
                            showOnlySkills={showOnlySkills}
                            currency_id={currency_id}
                            customSkills={services}
                            showCustomSkillsCount={services.length}
                            itemComponentCustomSubSkill={i => (
                                <SkillItem
                                    key={i.id}
                                    showEdit
                                    onEdit={() => {
                                        history.push(`/init-custom-skills/${i.id}`);
                                    }}
                                    onDelete={() => {
                                        dispatch(
                                            deleteCustomSkillSubSkillsRequest({
                                                id,
                                                service_id: i.id,
                                            })
                                        );
                                    }}
                                    currency_id={currency_id}
                                    data={i}
                                />
                            )}
                        />
                    </div>
                    <div className={style.buttonContainer}>
                        <Button
                            onClick={() => {
                                history.push('/init-custom-skills');
                            }}
                            variant="contained"
                            color="primary"
                        >
                            {'+ '}
                            {t('services_add_a_unique_service')}
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </Skeleton>
    );
};

ServiceEdit.propTypes = {
    history: PropTypes.object.isRequired,
};

export default ServiceEdit;
